import './App.css';
import './Publications';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Publications from './Publications';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <div className="App">
                    <Route path='/' exact component={Home}/>
                    <Route path='/publications' exact component={Publications}/>
                </div>
            </Switch>
        </BrowserRouter>
    );
}

const Home = () => (
    <div>

        <header className="App-header">

            <h1 style={{color: "#ff9900"}}>Omar Faroque(Anik)</h1>

            <ul class="list-inline">
                {/*<li><a href="/blog"><span>read</span>Software Engineering</a></li>*/}
                <li><a href="https://nanofaroque.github.io/personal_presentations/"><span></span>Papers, Patents, and Talks</a></li>
                <li><a href="https://medium.com/algorithm-and-datastructure"><span>Blog </span>Algorithms & Data Structures</a></li>
                <li><a href="https://medium.com/system-design"><span>Software System </span>System Design</a></li>
                <li><a href="https://github.com/nanofaroque"><span>Fork </span>GitHub</a></li>
                <li><a href="https://twitter.com/faroque_eee"><span>Mention </span>Twitter</a></li>
                <li><a href="https://www.linkedin.com/in/the-omar/"><span>LinkedIn </span>LinkedIn</a></li>
                <li><a href="https://stackoverflow.com/users/3254692/omar-faroque-anik"><span>stackoverflow </span>stackoverflow</a></li>
                <li><a href="https://nanofaroque.github.io/50-shades-of-graphs/"><span>Books </span>Books</a></li>
                <li><a href="https://500px.com/p/faroque_eee?view=photos"><span>Photography </span>Photography</a></li>
            </ul>
        </header>
    </div>

);

export default App;
