import './App.css';
import React,{useState,useEffect} from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Row,
    Col
  } from "reactstrap";
var moment = require("moment");

function Publications() {

  return (
<div className="full-screen-container">
  <h1 className="Publications-header">Omar's Publications</h1>
  <a href="https://medium.com/p/69c242c4ee2b" style={{ fontSize: '30px' }}>1. Exploring CodePipeline for Java Spring Boot Applications and deploy as ECS Farget service</a>
  
</div>
  );
};
export default Publications;
